import React from 'react';
import adobe from '../../Assets/images/industrySupporters/adobe.jpg';
import aha from '../../Assets/images/industrySupporters/aha.jpg';
import amd from '../../Assets/images/industrySupporters/amd.jpg';
import aputure from '../../Assets/images/industrySupporters/aputure.jpg';
import autodesk from '../../Assets/images/industrySupporters/autodesk.jpg';
import basilicFly from '../../Assets/images/industrySupporters/basilicFly.jpg';
import bot from '../../Assets/images/industrySupporters/bot.jpg';
import canon from '../../Assets/images/industrySupporters/canon.jpg';
import digitalDomain from '../../Assets/images/industrySupporters/digitalDomain.jpg';
import dneg from '../../Assets/images/industrySupporters/dneg.jpg';
import epicGames from '../../Assets/images/industrySupporters/epicGames.jpg';
import foundry from '../../Assets/images/industrySupporters/foundry.jpg';
import greenGold from '../../Assets/images/industrySupporters/greenGold.jpg';
import hornbill from '../../Assets/images/industrySupporters/hornbill.jpg';
import krafton from '../../Assets/images/industrySupporters/krafton.jpg';
import nazara from '../../Assets/images/industrySupporters/nazara.jpg';
import rotomaker from '../../Assets/images/industrySupporters/rotomaker.jpg';
import sony from '../../Assets/images/industrySupporters/sony.jpg';
import supermicro from '../../Assets/images/industrySupporters/supermicro.jpg';
import technicolor from '../../Assets/images/industrySupporters/technicolor.jpg';
import toonz from '../../Assets/images/industrySupporters/toonz.jpg';
import unity from '../../Assets/images/industrySupporters/unity.jpg';
import unrealEngine from '../../Assets/images/industrySupporters/unrealEngine.jpg';
import victoriaStateGovernement from '../../Assets/images/industrySupporters/victoriaStateGovernement.jpg';
import wacom from '../../Assets/images/industrySupporters/wacom.jpg';
import winzo from '../../Assets/images/industrySupporters/winzo.jpg';
import xpPen from '../../Assets/images/industrySupporters/xpPen.jpg';
import zeiss from '../../Assets/images/industrySupporters/zeiss.jpg';
import ves from '../../Assets/images/ecosystemPartners/ves.jpeg';
import abai from '../../Assets/images/ecosystemPartners/abai.jpeg';
import animationXpress from '../../Assets/images/ecosystemPartners/animationXpress.jpeg';
import asfiaIndia from '../../Assets/images/ecosystemPartners/asifaIndia.jpeg';
import cii from '../../Assets/images/ecosystemPartners/cii.jpeg';
import ficci from '../../Assets/images/ecosystemPartners/ficci.jpeg';
import fmx from '../../Assets/images/ecosystemPartners/fmx.jpeg';
import hysea from '../../Assets/images/ecosystemPartners/hysea.jpeg';
import iacc from '../../Assets/images/ecosystemPartners/iacc.jpeg';
import investIndia from '../../Assets/images/ecosystemPartners/investIndia.jpeg';
import meai from '../../Assets/images/ecosystemPartners/meai.jpeg';
import mesc from '../../Assets/images/ecosystemPartners/mesc.jpeg';
import sepc from '../../Assets/images/ecosystemPartners/sepc.jpeg';
import tca from '../../Assets/images/ecosystemPartners/tca.jpeg';
import vfx from '../../Assets/images/ecosystemPartners/vfx.jpeg';
import pickle from '../../Assets/images/industrySupporters/pickle.svg';

export const IndustrySupport = () => {
    const industrySupporters = [
        {
            image: unrealEngine,
            link: "https://www.unrealengine.com/en-US"
        },
        {
            image: sony,
            link: "https://www.sony.com/en/"
        }, {
            image: nazara,
            link: "https://www.nazara.com/"
        }, {
            image: autodesk,
            link: "https://www.autodesk.com/"
        }, {
            image: amd,
            link: "https://www.amd.com/en.html"
        }, {
            image: adobe,
            link: "https://www.adobe.com/in/"
        }, {
            image: aha,
            link: "https://www.aha.video/"
        }, {
            image: digitalDomain,
            link: "https://digitaldomain.com/"
        }, {
            image: foundry,
            link: "https://www.foundry.com/"
        }, {
            image: rotomaker,
            link: "https://rotomaker.com/"
        }, {
            image: dneg,
            link: "https://www.dneg.com/"
        }, {
            image: epicGames,
            link: "https://store.epicgames.com/en-US/"
        }, {
            image: zeiss,
            link: "https://www.zeiss.co.in/corporate/home.html"
        }, {
            image: toonz,
            link: "https://toonz.co/"
        }, {
            image: basilicFly,
            link: "https://www.basilicflystudio.com/"
        }, {
            image: wacom,
            link: "https://www.wacom.com/en-in"
        }, {
            image: xpPen,
            link: "https://www.xp-pen.com/"
        }, {
            image: greenGold,
            link: "https://www.greengold.tv/"
        }, {
            image: aputure,
            link: "https://www.aputure.com/"
        }, {
            image: unity,
            link: "https://unity.com/"
        }, {
            image: victoriaStateGovernement,
            link: "https://www.vic.gov.au/"
        }, {
            image: winzo,
            link: "https://www.winzogames.com/"
        }, {
            image: technicolor,
            link: "https://www.technicolor.com/"
        }, {
            image: canon,
            link: "https://in.canon/"
        }, {
            image: krafton,
            link: "https://krafton.com/en/"
        }, {
            image: supermicro,
            link: "https://www.supermicro.com/en/home"
        }, {
            image: hornbill,
            link: "https://hornbillstudios.com/"
        }, {
            image: bot,
            link: "https://botvfx.com/"
        },
        {
            image:pickle,
            link:"https://pickle.co.in/"
        }
    ];
    const ecosystemPartners = [
        {
            image: ves,
            link: "https://www.vesglobal.org/group/india/"
        },
        {
            image: abai,
            link: "https://abai.avgc.in/"
        },
        {
            image: mesc,
            link: "https://www.mescindia.org/"
        },
        {
            image: asfiaIndia,
            link: "https://www.asifaindia.com/"
        },
        {
            image: meai,
            link: "https://meai.in/"
        },
        {
            image: vfx,
            link: "https://www.vfxexpress.com/"
        },
        {
            image: animationXpress,
            link: "https://www.animationxpress.com/"
        },
        {
            image: iacc,
            link: "https://www.iaccindia.com/"
        },
        {
            image: hysea,
            link: "https://hysea.in/"
        },
        {
            image: investIndia,
            link: "https://www.investindia.gov.in/"
        },
        {
            image: ficci,
            link: "https://www.ficci.in/api/home"
        },
        {
            image: sepc,
            link: "https://www.servicesepc.org/"
        },
        {
            image: cii,
            link: "https://www.cii.in/"
        },
        {
            image: fmx,
            link: "https://fmx.de/en/home"
        },
        {
            image: tca,
            link: "https://thetca.in/"
        }
    ];
  return (
    <div>
         <div className="agenda-title" data-aos="fade-right" data-aos-delay="100">
                INDUSTRY SUPPORTERS
            </div>
            <div className="logos">
                {industrySupporters.map((supporter, index) => (
                    <div key={index} className="imgSupporter" data-aos="fade-down" data-aos-delay="100">
                        <div className="d-flex justify-content-center">
                            <a href={supporter.link} target="_blank" rel="noopener noreferrer">
                                <img src={supporter.image} alt="" loading="lazy"/>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className="agenda-title indiaJoy__topTitle" data-aos="fade-right" data-aos-delay="100">
                ECOSYSTEM PARTNERS
            </div>
            <div className="logos">
                {ecosystemPartners.map((partner, index) => (
                    <div key={index} className="imgSupporter" data-aos="fade-down" data-aos-delay="100">
                        <div className="d-flex justify-content-center">
                            <a href={partner.link} target="_blank" rel="noopener noreferrer">
                                <img src={partner.image} alt="" loading="lazy"/>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
    </div>
  )
}
