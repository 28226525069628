import React from 'react';
import buyTickets from '../../Assets/images/buyTickets.jpeg';
import { Link } from 'react-router-dom';

export const BuyTicket = () => {
    return (
        <div className='indiaJoy__bgforcards'>
            <div className='purchaseTicketCardBg'>
                <div className='purchaseTicketCard'>
                    <div className='content'>
                        <h1 className='agenda-title__contact' style={{ textAlign: 'left' }} data-aos="fade-down" data-aos-delay="100">BE PART OF INDIAJOY 2024</h1>
                        <p className='description indiaJoy__meet__container' data-aos="fade-down" data-aos-delay="200">
                            Meet at Asia's biggest media and entertainment congregation - where the world gathers to network, trade, collaborate, share the best-in-class ideas, and champion the next generation of innovation in the digital entertainment arena.
                        </p>
                        <button className="explore-button" data-aos="fade-down" data-aos-delay="300">
                            {/* <Link to="/register" className='nav-link'>BUY TICKETS</Link> */}
                            <a href="https://events.tecogis.com/indiajoy2024/select-pass" className='nav-link' target="_blank">BUY TICKETS</a>
                            </button>
                    </div>
                    <div className='buyTickets'>
                        <img src={buyTickets} alt="Buy Tickets" className="img-fluid" loading="lazy"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
