import React from 'react';
import Menu from '../Home/menu';
import { BuyTicket } from '../Contact/buyTicket';
import { Footer } from '../Home/footer';
import IgdcImg from '../../Assets/images/igdc.png';
import EsportsImg from '../../Assets/images/esports.png';
import ComicsImg from '../../Assets/images/hyderabadcomicon.png';
import vfx from '../../Assets/images/vfx.png';
import ANIMATION from '../../Assets/images/desi.png';
import fILM from '../../Assets/images/cinematicaexpo.png';
import ANIMATION1 from '../../Assets/images/india.png';
import LearnMore from '../../Assets/images/Learn More - Button.svg';
import { Link } from 'react-router-dom';

export const Agenda = () => {
  const aData = [
    {
      id: 1,
      imgSrc: IgdcImg,
      title: 'gaming',
      status: LearnMore,
    },
    // {
    //   id: 2,
    //   imgSrc: EsportsImg,
    //   title: 'E-SPORTS',
    //   status: LearnMore,
    // },
    // {
    //   id: 3,
    //   imgSrc: ComicsImg,
    //   title: 'Comics',
    //   status: LearnMore,
    // },
    {
      id: 5,
      imgSrc: vfx,
      title: 'vfx',
      status: LearnMore,
    },
    {
      id: 6,
      imgSrc: ANIMATION,
      title: 'ANIMATION',
      status: LearnMore,
    },
    {
      id: 7,
      imgSrc: fILM,
      title: 'fILM',
      status: LearnMore,
    },
    {
      id: 8,
      imgSrc: ANIMATION1,
      title: 'ANIMATION',
      status: LearnMore,
    },
  ];

  return (
    <div>
      <Menu />
      <div className='indiaJoy__agenda__wrapper'>
        <h3 data-aos="fade-right" data-aos-delay="100">Agenda</h3>
        <div className='indiaJoy__agenda__container'>
            {aData.map(item => (
              <div className='indiaJoy__agenda__items' data-aos="fade-down" data-aos-delay="100">
                <div><h4>{item?.title}</h4></div>
                <div className='indiaJoy__customGrid'>
                <div className='indiaJoy__agenda__grid'>
                  <div key={item.id} className='indiaJoy__agenda__sections'>
                    <img src={item.imgSrc} alt={item.title} loading="lazy"/>
                    <Link to='/commingSoon'>
                      <button><img src={LearnMore} alt='LearnMore' loading="lazy"/></button>
                    </Link>
                  </div>
                 </div>
                </div>
              </div>
            ))}
          </div>
      </div>
      <BuyTicket />
      <Footer />
    </div>
  )
}
