import React from 'react';
import Menu from '../Home/menu';
import { BuyTicket } from '../Contact/buyTicket';
import { Footer } from '../Home/footer';
import pastEdotionBannerImg from '../../Assets/images/Hero Image 1.svg';

export const PastEditions = () => {
    const pData = [
        {
            year:"2018",
            link:"https://www.youtube.com/embed/Esb_Kh3i1lE"
        },
        {
            year:"2019",
            link:"https://www.youtube.com/embed/AlmIeMUaNdc"
        },
        {
            year:"2021",
            link:"https://www.youtube.com/embed/ocOzFaAaHzw"
        },
        {
            year:"2022 - VFX Summit",
            link:"https://www.youtube.com/embed/jqCGm67Bl6s"
        },
        {
            year:"2022 - IGDC",
            link:"https://www.youtube.com/embed/I672STWIEwA"
        },
        {
            year:"2022 - Indywood",
            link:"https://www.youtube.com/embed/f8kvO3v34sg"
        }
    ]
    return (
        <div>
            <Menu />
            <div className='indiaJoy__pastEdition__wrapper'>
                <div className='indiaJoy__pastEdition__container'>
                    <div className='indiaJoy__pastEdition__item1'>
                        <h2 data-aos="fade-right" data-aos-delay="100">Past <br/> Editions</h2><br/>
                        <h3 data-aos="fade-down" data-aos-delay="100">2023</h3>
                        <div className='indiaJoy__pastEdition__card' data-aos="fade-down" data-aos-delay="200">
                            <iframe width="990" height="557" src="https://www.youtube.com/embed/ARYjTbP15Aw" title="IndiaJoy Rewind 2023 - After Movie" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className='indiaJoy__pastEdition__item2' data-aos="fade-down" data-aos-delay="100">
                        <img src={pastEdotionBannerImg} alt='pastEdotionBannerImg' loading="lazy"/>
                    </div>
                </div>
                <div className='indiaJoy__pastEdition__section'>
                    {pData.map((item, index) => (
                        <div key={index} className='indiaJoy__pastEdition__item3' data-aos="fade-down" data-aos-delay="100">
                           <h2>{item?.year}</h2>
                           <div className='indiaJoy__pastEdition__card2'>
                                <iframe width="990" height="557" src={item?.link} title={`Video of ${item?.year}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                           </div>
                        </div>
                    ))}
                </div>
            </div>
            <BuyTicket />
            <Footer />
        </div>
    )
}
