import React from "react";
import { Footer } from './footer';
import Menu from './menu';
import GlobalIcon from '../../Assets/images/globalIcon.svg';

export const GlobalPage = () => {
    return (
        <div>
            <Menu />
            <div className="indiaJoy__global__bannerSection">
                <img src={GlobalIcon} alt="GlobalIcon" loading="lazy"/>
            </div>
            <div className="indiaJoy__global__container">
                <div className="indiaJoy__global__section">
                    <h1>IndiaJoy Global Youth<br /> Program Returns</h1>
                    <p>IndiaJoy, India's premier Gaming, Animation, Digital Media, AI, Web3, and Entertainment week, is thrilled to announce the return of its Global Youth Program for the fourth consecutive year. AI accelerated our digital future, and we're committed to empowering the next generation to embrace this transformative era.</p>
                    <p>The IndiaJoy Global Youth Program 2024 is a unique platform designed to foster networking, knowledge sharing, and cross-border collaboration among 250 young leaders from around the world. Participants will gain invaluable insights into the design industry, connect with peers, and build lasting partnerships.</p>
                </div>
                <div className="indiaJoy__global__details">
                    <h2>Benefits</h2>
                    <ul>
                        <li>Represent your country on a global stage.</li>
                        <li>Exclusive access to IndiaJoy events and festivals from November 13-17.</li>
                        <li>Participate in workshops and earn a certificate of participation.</li>
                        <li>Join an elite network of young global leaders.</li>
                        <li>Learn from industry pioneers.</li>
                        <li>Enjoy complimentary accommodation and conference fees.</li>
                        <li>Visa Assistance and guidance</li>
                    </ul>
                </div>
                <div className="indiaJoy__global__itemList">
                    <h2>Eligibility</h2>
                    <p>Applicants must be:</p>
                    <ul>
                        <li>Passionate about the digital industry and youth leadership.</li>
                        <li>Between the ages of 18 and 35.</li>
                    </ul>
                </div>
                <div className="indiaJoy__global__applySection">
                    <h2>How to Apply</h2>
                    <p>Applications are open until October 1, 2024. Successful candidates will be selected on a rolling basis each week. Upon selection, participants will need to confirm their attendance by providing flight details.</p>
                </div>
                <button>
                    <a href="https://forms.gle/XAqH63BUW6APwFKW7" target="_blank" rel="noopener noreferrer">
                        Apply now
                    </a>
                </button>
            </div>
            <Footer />
        </div>
    )
}