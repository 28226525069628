import React, { useEffect, useState } from "react";
import xLogo from "../../Assets/images/xLogo.svg";
import linkLogo from "../../Assets/images/linkd.svg";
import instaLogo from "../../Assets/images/insta.svg";
import metaLogo from "../../Assets/images/meta.svg";
import youtubeLogo from "../../Assets/images/youtube.svg";
import { subscribeToNewsletter } from "../utils"; // Import the function

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [sucessMsg, setSuccessMsg] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    console.log("Email input changed to:", event.target.value); // Log email input changes
  };

  // const handleSubscribeClick = async () => {
  //   console.log("Subscribe button clicked with email:", email); // Log the email on button click

  //   // Simple email validation regex
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (emailRegex.test(email)) {
  //     setError("");
  //     try {
  //       const result = await subscribeToNewsletter(email); // Call the function from utils.js
  //       console.log("Subscription result:", result); // Log the result
  //       // alert('Subscription successful!');
  //       setSuccessMsg("Thank you for subscribing");
  //       setError("");
  //       setEmail(""); // Clear the email input field after successful subscription
  //     } catch (error) {
  //       // setError("Subscription failed. Please try again later.");
  //       setSuccessMsg("");
  //       console.error("Subscription failed:", error); // Log subscription failure

  //       // const error = errRes as AxiosError;
  //       if (error) {
  //         const errorData = error.error;
  //         setError(errorData);
  //       } else {
  //           setError("An unknown error occurred.");
  //       }
  //     }

  //   } else {
  //     setError("Please enter a valid email address.");
  //   }
  // };

  // Function to handle the click event for subscribing to the newsletter
  const handleSubscribeClick = async () => {
    console.log("Subscribe button clicked with email:", email); // Log the email on button click

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setError("");
      try {
        const result = await subscribeToNewsletter(email); // Call the function from utils.js
        console.log("Subscription result:", result); // Log the result
        setSuccessMsg("Thank you for subscribing");
        setError("");
        setEmail(""); // Clear the email input field after successful subscription
      } catch (error) {
        setSuccessMsg("");
        console.error("Subscription failed:", error); // Log subscription failure

        // Display the error message from the API
        setError(error.message || "An unknown error occurred.");
      }
    } else {
      setError("Please enter a valid email address.");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="speakers-background">
      <div className="indiaJoy__footer__container">
        <div className="footer-title">INDIAJOY 2024</div>
        <div className="subscribe-content">
          {/* <div className="subscribe subscribe_input">
            <input
              type="email"
              className="emailInput"
              placeholder="Enter your Email"
              value={email}
              onChange={handleEmailChange}
            />
            {error && (
              <div
                className="email-error"
                style={{ marginBottom: 10, fontSize: 12 }}
              >
                {error}
              </div>
            )}
            {sucessMsg && (
              <div
                className="email-success"
                style={{ marginBottom: 10, fontSize: 12 }}
              >
                {sucessMsg}
              </div>
            )}
          </div> */}
          {/* <div className="subscribe">
            <button
              className="subscribe-button btn"
              onClick={handleSubscribeClick}
            >
              SUBSCRIBE
            </button>
          </div> */}

          <iframe
            src="https://api.leadconnectorhq.com/widget/form/Xyzs3d4rim9AubopOlve" 
            style={{width:'100%', height:'100%'}}           
            id="inline-Xyzs3d4rim9AubopOlve"
            // data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="indiajoy newslatter"
            data-height="820"
            // data-layout-iframe-id="inline-Xyzs3d4rim9AubopOlve"
            // data-form-id="Xyzs3d4rim9AubopOlve"
            title="indiajoy newslatter"
          ></iframe>
          
        </div>
        <div className="address-content">
          IMAGE Incubation – Office 1, DivyaShree Building, Inorbit Mall Rd,
          HITEC City, Hyderabad-500081, Telangana, India.
        </div>
        <div className="indiaJoy__rightPanel__container__footer">
          <div className="indiaJoy__rightPanel__footer">
            <div
              className="indiaJoy__rightPanel__section"
              data-aos="fade-down"
              data-aos-delay="100"
            >
              <a
                href="https://twitter.com/indiajoyin?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xLogo} alt="xLogo" loading="lazy" />
              </a>
            </div>
            <div
              className="indiaJoy__rightPanel__section"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              <a
                href="https://www.linkedin.com/company/indiajoy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkLogo} alt="linkLogo" loading="lazy" />
              </a>
            </div>
            <div
              className="indiaJoy__rightPanel__section"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              <a
                href="https://www.instagram.com/indiajoyfestival/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instaLogo} alt="instaLogo" loading="lazy" />
              </a>
            </div>
            <div
              className="indiaJoy__rightPanel__section"
              data-aos="fade-down"
              data-aos-delay="400"
            >
              <a
                href="https://www.facebook.com/Indiajoy.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={metaLogo} alt="metaLogo" loading="lazy" />
              </a>
            </div>
            <div
              className="indiaJoy__rightPanel__section"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              <a
                href="https://www.youtube.com/channel/UCe8cEAMgMZbqS2cf8t4_JQg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtubeLogo} alt="youtubeLogo" loading="lazy" />
              </a>
            </div>
          </div>
        </div>
        <hr className="styled-hr" />
        <div className="footer-content">
          © Copyright ©2024 Indiajoy. All Rights Reserved
        </div>
      </div>
    </div>
  );
};
